// Colours
$body-color: #000;
$text-color: #000;
$primary-color: #2B3B50;
$primary-active-color: #000000;
$secondary-color: #FFA59B;
$link-color: $primary-color;
$heading-color: #000000;

// Fonts
$text-font-size: 14px;
$text-font: 'Poppins', Helvetica, Arial, sans-serif;
$quote-font: 'Poppins', Georgia, serif;
$text-bold-weight: 700;
$text-semi-bold-weight: 600;

// Sizes
$container-width: 1182px;

// General
$transition: 0.3s ease-in-out;