@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	background: #F8F8F8;
	padding-top: 60px;
	text-align: center;
	font-family: $text-font;
	font-size: $text-font-size;
	color: $body-color;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}

* {
	box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	-moz-box-sizing: border-box; 
}
	
	a { 
		color: $link-color; 
		text-decoration: none; 
		transition: $transition;
		&:hover,
		&:active,
		&:focus {
			color: $text-color;
		}
	}
	
	.clearfix { display: block; clear: both; }

	img { max-width: 100%; height: auto; }

	// .loader {
	// 	background: url(../images/loader.gif) no-repeat center 16px #FFF;
	// 	border-radius: 4px;
	// 	padding: 56px 24px 16px 24px;
	// 	font-size: 12px;
	// 	line-height: 21px;
	// 	color: #000;
	// 	margin: 0 auto;
	// 	border: 1px solid rgba(0,0,0,0.1);
	// 	text-align: center;
	// 	width: 120px;
	// }

	.loader {
		width: 30px;
		height: 30px;
		padding: 4px;
		border-radius: 50%;
		background: #000;
		--_m: 
		conic-gradient(#0000 10%,#000),
		linear-gradient(#000 0 0) content-box;
		-webkit-mask: var(--_m);
		      mask: var(--_m);
		-webkit-mask-composite: source-out;
		      mask-composite: subtract;
		animation: l3 1s infinite linear;
	}
	@keyframes l3 {to{transform: rotate(1turn)}}