body {
	padding-bottom: 60px;
	position: relative;
	min-height: 100vh;
}
.appFooter {
	background: #101010;
	padding: 18px 0;
	position: absolute;
	z-index: 1000;
	bottom: 0px;
	width: 100%;
	.container {
		max-width: 1340px;
		margin: 0 auto;
		text-align: left;
	}
	p {
		font-size: 8px;
		line-height: 12px;
		color: #FFF;
	}
}

@media all and (max-width: 1440px) and (min-width: 0px) {
	.appFooter {
		.container {
			margin: 0 32px;
			max-width: none;
			width: auto;
		}
	}
}