.modalBack {
	visibility: hidden;
	opacity: 0;
	left: -99999px;
	background: rgba(217, 217, 217, 0.5);
	position: fixed;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 10000;
	transition: .3s opacity;
	&.active {
		left: 0px;
		opacity: 1;
		visibility: visible;
	}
}
.modalWindow {
	visibility: hidden;
	opacity: 0;
	left: -99999px;
	z-index: 10001;
	position: fixed;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	min-height: 100px;
	width: 660px;
	transition: .3s opacity;
	.modalWindowInner {
		background: #FFF;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		border-radius: 5px;
	}
	.modalWindowClose {
		z-index: 10002;
		background: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.4 8L16 16H15.1636L7.89091 8.31818L0.836364 16H0L7.49091 7.88636L0 0H0.836364L8 7.56818L15.1636 0H16L8.4 8Z" fill="currentColor"/></svg>') no-repeat center center;
		width: 36px;
		height: 36px;
		padding: 10px;
		display: block;
		position: absolute;
		top: 22px;
		right: 22px;
		color: #101010;
		border-radius: 5px;
		&:hover,
		&:active,
		&:focus {
			background-color: #EEE;
		}
	}
	&.active {
		left: 50%;
		opacity: 1;
		visibility: visible;
	}
	.modalConfirmButtons {
		padding: 16px 0 0 0;
		.button {
			margin: 0 5px;
		}
	}
}

.modalUserProfile {
	position: relative;
	text-align: left;
	.modalUserProfileHeader {
		padding: 41px 48px 30px 48px;
		h3 {
			font-size: 18px;
			line-height: 27px;
			font-weight: bold;
			color: #101010;
			margin: 0 0 2px 0;
		}
		p {
			font-size: 14px;
			line-height: 21px;
			color: #000;
		}
	}
	.modalUserProfileContent {
		display: block;
		padding: 0 48px 64px 48px;
		.modalUserProfileItem {
			display: block;
			border-bottom: 1px solid rgba(0,0,0,0.05);
			padding: 10px 0;
			text-align: right;
			font-size: 12px;
			line-height: 18px;
			position: relative;
			min-height: 38px;
			span {
				position: absolute;
				left: 0px;
				top: 10px;
				display: block;
			}
		}
	}
}

.modalDownload {
	position: relative;
	text-align: left;
	.modalDownloadHeader {
		padding: 41px 48px 30px 48px;
		h3 {
			font-size: 18px;
			line-height: 27px;
			font-weight: bold;
			color: #101010;
			margin: 0 0 2px 0;
		}
		p {
			font-size: 14px;
			line-height: 21px;
			color: #000;
		}
	}
	.modalDownloadContent {
		display: block;
		padding: 0 48px 64px 48px;
		p {
			font-size: 14px;
			line-height: 21px;
			color: #000;
			margin: 0 0 10px 0;
		}
	}
}