.welcomeBanner {
	background: #FFF;
	border-radius: 5px;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
	position: relative;
	margin: 0 0 0 0;
	padding: 28px 28px 16px 221px;
	.welcomeBannerIcon {
		position: absolute;
		top: 27px;
		left: 28px;
		display: block;
	}
	h1 {
		font-size: 26px;
		line-height: 39px;
		color: #000;
		font-weight: 700;
		margin: 0 0 11px 0;
	}
	.welcomeBannerTourLink {
		font-size: 12px;
		line-height: 18px;
		display: inline-block;
		padding: 0 0 0 26px;
		position: relative;
		svg {
			position: absolute;
			top: 3px;
			left: 0px;
		}
	}
	.welcomeBannerDismiss {
		position: absolute;
		top: -10px;
		right: -10px;
		background: #FFF;
		z-index: 10;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		border: 1px solid #000;
		text-align: center;
		line-height: 16px;
		font-size: 10px;
		color: #000;
		font-weight: bold;
	}
}