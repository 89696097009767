.jobsTableFilters {
	padding: 10px 0 26px 20px;
	a {
		display: inline-block;
		vertical-align: top;
		font-size: 10px;
		line-height: 15px;
		margin: 0 50px 0 0;
		text-decoration: underline;
		color: $primary-color;
		&.active {
			font-weight: bold;
		}
	}
}

.jobsTable {
	background: #FFF;
	border-radius: 5px;
	position: relative;
	::-webkit-scrollbar {
		width: 22px;
	}
	::-webkit-scrollbar-track {
		background: #FFF;
	}
	::-webkit-scrollbar-thumb {
		background: rgba(16, 16, 16, 0.2);
		border-radius: 20px;
		border: 8px solid #FFF;
		cursor: pointer;
	}
	.loader {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
	}
	table {
		width: 100%;
		th {
			&.jobTableCellJob { width: 25%; }
			&.jobTableCellDescription { width: 30%; }
			&.jobTableCellProgress { width: 15%; }
			&.jobTableCellStatus { width: 15%; text-align: center; }
			&.jobTableCellDateDue { width: 15%; }
		}
		thead {
			height: 48px;
			display: table;
			width: 100%;
			table-layout: fixed;
			padding: 0 22px 0 0;
			border-bottom: 1px solid rgba(16, 16, 16, 0.1);
			th {
				padding: 15px 0 15px 31px;
				font-size: 12px;
				line-height: 18px;
				color: $primary-color;
				font-weight: bold;
			}
		}
		tbody {
			overflow-y: scroll;
			width: 100%;
			display: block;
			// max-height: 300px;
			padding: 0;
			height: calc(100vh - 325px);
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				td {
					padding: 31px 0 31px 31px;
					font-size: 10px;
					vertical-align: middle;
					line-height: 15px;
					color: $primary-color;
					border-bottom: 1px solid rgba(16, 16, 16, 0.05);
					&.jobTableCellJob {
						font-weight: bold;
						font-size: 12px;
						line-height: 18px;
						width: 25%;
					}
					&.jobTableCellDescription {  width: 30%; }
					&.jobTableCellProgress { width: 15%; }
					&.jobTableCellStatus { text-align: center; width: 15%; }
					&.jobTableCellDateDue { border-right: 1px solid rgba(16, 16, 16, 0.1); padding-right: 31px; width: 15%; }
				}
			}
			.noJobsFound {
				text-align: center;
				padding: 24px;
			}
		}
	}
}

.jobStatus {
	padding: 0 0 0 28px;
	position: relative;
	line-height: 15px;
	display: inline-block;
	span {
		width: 15px;
		height: 15px;
		border-radius: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		display: block;
	}
	&.jobStatusInProgress {
		span { background: #FFE8CE; }
	}
	&.jobStatusOverdue {
		span { background: #FBCFC9; }
	}
	&.jobStatusCompleted {
		span { background: #9CF6C5; }
	}
}