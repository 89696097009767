.dashboardTop {
	display: grid;
	grid-gap: 20px;
	row-gap: 20px;
	grid-template-columns: 1fr 1fr 2fr;
	margin: 0 0 22px 0;
	.dashboardLink {
		display: block;
		height: 130px;
		padding: 64px 20px 0 20px;
		border-radius: 5px;
		position: relative;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		svg {
			position: absolute;
			top: 38px;
			left: 20px;
		}
		.count {
			display: block;
			font-size: 22px;
			line-height: 30px;
			height: 30px;
			font-weight: bold;
			color: #101010;
			.loader {
				width: 20px;
				height: 20px;
				background: #101010;
				top: 2px;
				position: relative;
				padding: 3px;
			}
		}
		.label {
			display: block;
			font-size: 16px;
			line-height: 24px;
			color: #101010;
			opacity: 0.5;
		}
		.viewLink {
			display: block;
			position: absolute;
			top: 17px;
			right: 22px;
			font-size: 8px;
			line-height: 12px;
			text-decoration: underline;
			color: #000;
		}
		&.dashboardLinkDocuments {
			background: rgba(255, 232, 206, 0.6);
			&:hover,
			&:active,
			&:focus {
				background: rgba(255, 232, 206, 1);
			}
		}
		&.dashboardLinkJobs {
			background: rgba(255, 165, 155, 0.5);
			&:hover,
			&:active,
			&:focus {
				background: rgba(255, 165, 155, 1);
			}
		}
	}
	.dashboardContact {
		display: block;
		height: 130px;
		padding: 14px 20px 0 20px;
		border-radius: 5px;
		text-align: center;
		position: relative;
		background: $primary-color;
		p {
			font-size: 14px;
			line-height: 21px;
			color: #FFF;
			font-weight: bold;
			margin: 0 0 4px 0;
		}
		.dashboardContactPhone {
			display: block;
			font-size: 20px;
			line-height: 30px;
			color: #FFE8CE;
			font-weight: bold;
			margin: 0 0 13px 0;
			&:hover,
			&:active,
			&:focus {
				color: #FFF;
			}
		}
		.dashboardContactBookMeetingLink {
			color: #FFE8CE;
			border: 1px solid #FFE8CE;
			padding: 6px;
			font-size: 12px;
			line-height: 18px;
			display: inline-block;
			border-radius: 7px;
			&:hover,
			&:active,
			&:focus {
				color: #FFF;
				border-color: #FFF;
			}
		}
	}
}

.appBoxContentDashboardJobs {
	height: calc(100% - 48px);
}

.dashboardJobs {
	display: flex;
	width: 100%;
	align-items: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	.noJobsAdded {
		text-align: center;
		padding: 0 24px;
		line-height: 21px;
		font-size: 12px;
	}
	.dashboardJobsDonut {
		width: calc(100% - 120px);
		position: relative;
		.percentage {
			font-size: 50px;
			line-height: 75px;
			color: #334D6E;
			text-align: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			left: 0px;
			width: 215px;
		}
	}
	.dashboardJobsKey {
		width: 120px;
		flex-grow: 0;
		flex-shrink: 0;
		.dashboardJobsKeyItem {
			margin: 10px 0;
			padding: 0 0 0 30px;
			position: relative;
			font-size: 14px;
			line-height: 21px;
			color: #000;
			span {
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				width: 20px;
				height: 20px;
				border-radius: 100%;
			}
			&.dashboardJobsKeyItemCompleted {
				span {
					background: #9CF6C5;
				}
			}
			&.dashboardJobsKeyItemInProgress {
				span {
					background: #FFE8CE;
				}
			}
			&.dashboardJobsKeyItemOverdue {
				span {
					background: #FFA59B;
				}
			}
		}
	}
}

.noDocumentsAdded {
	text-align: center;
	padding: 0 24px;
	line-height: 21px;
	font-size: 12px;
}
.dashboardDocuments {
	margin: 0 0 -10px 0;
	.dashboardDocumentsItem {
		display: block;
		margin: 0 0 10px 0;
		background: url(../images/icon-document.png) no-repeat right 21px center rgba(16, 16, 16, 0.05);
		padding: 12px 84px 13px 21px;
		font-size: 12px;
		line-height: 18px;
		color: #000;
		&:hover,
		&:active,
		&:focus {
			background-color: rgba(16, 16, 16, 0.1);
		}
	}
}

.dashboardFinancialInsightsPlaceholder {
	text-align: center;
	position: relative;
	p {
		font-size: 14px;
		line-height: 21px;
		position: absolute;
		top: 86px;
		width: 100%;
		left: 0px;
		color: #000;
		text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
	}
}

.dashboardTeam {
	h2 {
		font-size: 22px;
		line-height: 33px;
		color: #101010;
		font-weight: normal;
		margin: 0 0 25px 0;
	}
	.dashboardTeamMembers {
		display: grid;
		column-gap: 21px;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		.dashboardTeamMember {
			.dashboardTeamMemberLink {
				display: block;
				.dashboardTeamMemberPic {
					background: #FFF;
					display: block;
					border-radius: 5px;
					height: 218px;
					position: relative;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
					margin: 0 0 21px 0;
					img {
						position: absolute;
						bottom: 0px;
					}
				}
				.dashboardTeamMemberName {
					text-decoration: underline;
					font-size: 12px;
					line-height: 18px;
					font-weight: bold;
					text-align: center;
					display: block;
					color: #000;
				}
				&:hover,
				&:active,
				&:focus {
					.dashboardTeamMemberPic {
						box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}
}

.modalTeamMember {
	position: relative;
	.modalTeamMemberHeader {
		padding: 41px 92px 50px 92px;
		h3 {
			font-size: 18px;
			line-height: 27px;
			font-weight: bold;
			color: #101010;
			margin: 0 0 2px 0;
		}
		p {
			font-size: 14px;
			line-height: 21px;
			color: #000;
		}
	}
	.modalTeamMemberContent {
		display: flex;
		align-content: top;
		padding: 0 34px 0 55px;
		.modalTeamMemberContentPic {
			flex-shrink: 0;
			flex-grow: 0;
			img { margin: 0px; display: block; }
		}
		.modalTeamMemberContentProfile {
			padding: 45px 0 90px 0;
			width: 193px;
			p {
				font-size: 14px;
				line-height: 21px;
				margin: 0 0 30px 0;
				color: #000;
			}
			a {
				text-decoration: underline;
				color: #000;
			}
		}
	}
	.modalTeamMemberSocials {
		position: absolute;
		bottom: 26px;
		right: 30px;
		a {
			display: inline-block;
			vertical-align: top;
			margin: 0 0 0 21px;
		}
	}
}