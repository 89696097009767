.referralProcess {
	display: grid;
	column-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 658px;
	margin: 0 0 20px 0;
	.referralProcessStep {
		background: rgba(255, 165, 155, 0.5);
		padding: 7px 10px 17px 10px;
		position: relative;
		text-align: center;
		border-radius: 5px;
		.referralProcessStepArrow {
			background: url(../images/referral-arrow.svg) no-repeat;
			width: 130px;
			height: 45px;
			display: block;
			// content: '';
			position: absolute;
			top: -23px;
			left: 154px;
		}
		p {
			font-size: 12px;
			line-height: 18px;
			font-weight: 600;
			color: $primary-color;
		}
		h3 {
			font-size: 25px;
			line-height: 37.5px;
			color: $primary-color;
			font-weight: bold;
		}
		&.referralProcessStep2 {
			background: rgba(255, 232, 206, 0.5);
			h3 {
				color: #FFA59B;
			}
		}
		// &.referralProcessStep3 {
		// 	&:after { display: none; }
		// }
	}
}

.referralWidgets {
	padding: 0 0 0 50%;
	position: relative;
	.referralWidgetsLeft {
		position: absolute;
		left: 0px;
		top: 0px;
		height: 100%;
		width: calc(50% - 20px);
		.appBox {
			.appBoxContent {
				height: 292px;
			}
		}
	}
	.referralWidgetsRight {
		.appBox {
			margin: 0 0 20px 0;
		}
	}
	.appBox {
		.appBoxHeader {
			padding-right: 20px;
			h4 {
				font-weight: bold;
			}
		}
	}
	.referralValue {
		display: flex;
		align-items: center;
		padding: 10px;
		.referralValueAmount {
			font-size: 35px;
			line-height: 52px;
			font-weight: bold;
			color: $primary-color;
			padding: 0 50px 0 0;
		}
		.referralValueLabel {
			font-size: 14px;
			line-height: 21px;
			color: #000;
		}
	}
	.referralTreesPlanted {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 10px;
		position: relative;
		.referralTreesPlantedAmount {
			font-size: 35px;
			line-height: 52px;
			font-weight: normal;
			color: $primary-color;
			padding: 0 20px 0 0;
		}
		.referralTreesPlantedLabel {
			font-size: 14px;
			line-height: 21px;
			color: $primary-color;
		}
		a {
			position: absolute;
			top: 17px;
			right: 10px;
		}
	}
}