.button {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	border: none;
	font-family: $text-font;
	
	background: $primary-color;
	border-radius: 5px;
	padding: 10px 24px 10px 24px;
	line-height: 20px;
	height: 40px;
	font-size: 12px;
	font-weight: bold;
	color: #FFF;
	position: relative;
	display: inline-block;
	overflow: hidden;
	text-align: center;

	svg {
		position: relative;
		top: 0px;
	}

	&:hover {
		color: #FFF;
		background: #000;
	}

	&:active,
	&:focus {
		color: #FFF;
		background: #000;
	}

	&.buttonBordered {
		border: 1px solid $primary-color;
		color: $primary-color;
		background: transparent;
		&:hover,
		&:active,
		&:focus {
			background: $primary-color;
			color: #FFF;
		}
	}

}