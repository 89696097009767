.formGroup {
	margin: 0 0 13px 0;
	&.formGroupHide {
		visibility: hidden;
		position: absolute;
		left: -9999px;
	}
	.formLabel {
		display: block;
		font-size: 8px;
		line-height: 12px;
		color: #000;
		margin: 0 0 3px 0;
		font-weight: 300;
	}
	textarea,
	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		border: 1px solid rgba(16, 16, 16, 0.5);
		border-radius: 2px;
		width: 100%;
		height: 25px;
		font-family: $text-font;
		color: $text-color;
		padding: 2px 8px 2px 8px;
		line-height: 19px;
		font-size: 16px;
		position: relative;
		z-index: 1;
		box-shadow: none;
		background: transparent;
	}
	textarea { height: 105px; }

	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		position: absolute;
		cursor: pointer;
		border: none;
		top: 0px;
		left: 0px;
		font-family: $text-font;
		color: $text-color;
		height: 48px;
		padding: 11px 17px 14px 17px;
		line-height: 22px;
		font-size: 16px;
		width: 100%;
		width: calc(100% + 40px);
		background: transparent;
		z-index: 1;
		overflow: hidden;
		background: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%206l5%205%205-5%202%201-7%207-7-7%202-1z%22%20fill%3D%22%23555%22%2F%3E%3C%2Fsvg%3E) no-repeat right 10px top 53%;
		background-size: 18px 18px;
	}
}

.formCheckboxes {
	.formCheckbox {
		margin: 0 0 20px 0;
		text-align: left;
		label {
			display: block;
			padding: 0 30px 0 0;
			position: relative;
			font-size: 14px;
			line-height: 21px;
			color: #101010;
			cursor: pointer;
			&:before {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 21px;
				height: 21px;
				background: rgba(43, 59, 80, 0.15);
				content: '';
				display: block;
				transition: $transition;
			}
			&:hover {
				&:before {
					background: rgba(43, 59, 80, 0.3);
				}
			}
		}
		input {
			position: absolute;
			left: -9999px;
			display: block;
		}
		input:checked + label:after {
			position: absolute;
			top: 0px;
			right: 0px;
			width: 21px;
			height: 21px;
			background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/></svg>') no-repeat center center $primary-color;
			background-size: 20px 20px;
			content: '';
			display: block;
		}
	}
}

.formSubmit {
	padding: 5px 0 0 0;
	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		background: $primary-color;
		width: 100%;
		border: none;
		border-radius: 5px;
		font-size: 12px;
		line-height: 18px;
		color: #FFF;
		font-weight: bold;
		text-align: center;
		cursor: pointer;
		padding: 3px 10px;
	}
}

.errorMessage {
	position: relative;	
	margin: 0 auto 32px auto;
	background: rgba(255, 60, 95, 0.2);
	padding: 5px 8px 4px 31px;
	border-radius: 2px;
	font-size: 10px;
	line-height: 12px;
	display: block;
	width: 246px;
	.errorMessageIcon {
		display: block;
		position: absolute;
		top: 4px;
		left: 10px;
		border-radius: 100%;
		border: 1px solid #101010;
		line-height: 11px;
		text-align: center;
		width: 13px;
	}
}