.appTiles {
	display: grid;
	column-gap: 20px;
	row-gap: 16px;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 658px;
	margin: 0 0 44px 0;
	a {
		display: block;
		background: #FFF;
		border-radius: 5px;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		padding: 141px 32px 26px 32px;
		position: relative;
		text-align: center;
		.appTileLogo {
			position: absolute;
			top: 21px;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			height: 100px;
			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
			}
		}
		h4 {
			font-size: 12px;
			line-height: 18px;
			font-weight: bold;
			margin: 0 -20px 8px -20px;
			color: #000;
		}
		.appTileDescription {
			display: block;
			font-size: 10px;
			line-height: 15px;
			color: #000;
		}
		&:hover,
		&:active,
		&:focus {
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
		}
	}
}

.linksList {
	max-width: 658px;
	h2 {
		font-size: 22px;
		line-height: 33px;
		color: #101010;
		padding: 0 0 0 31px;
		margin: 0 0 20px 0;
	}
	a {
		display: flex;
		margin: 0 0 10px 0;
		background: #FFF;
		border-radius: 5px;
		padding: 12px 125px 13px 31px;
		position: relative;
		color: #000;
		.linksListItemTitle {
			display: block;
			font-size: 12px;
			line-height: 18px;
			font-weight: bold;
			width: 195px;
			flex-shrink: 0;
			flex-grow: 0;
		}
		.linksListItemDescription {
			display: block;
			font-size: 12px;
			line-height: 18px;
			flex-grow: 1;
		}
		img {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			right: 73px;
		}
		&:hover,
		&:active,
		&:focus {
			color: #000;
			background: rgba(0,0,0,0.05);
		}
	}
}
