.appPanel {
	padding: 0 0 0 277px;
	max-width: 1185px;
	text-align: left;
	.appSidebar {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 277px;
		background: #FFF;
		box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.05);
		height: 100vh;
		padding: 60px 0 0 0;
		z-index: 1;
	}
	.appContent {
		padding: 28px 20px;
	}
	.appContentHeader {
		padding: 0 0 38px 0;
		margin: -8px 0 0 0;
		h1 {
			font-size: 35px;
			line-height: 52px;
			color: #000;
			font-weight: bold;
			margin: 0 0 21px 0;
		}
		.appBreadcrumb {
			margin: -11px 0 12px 0;
			font-size: 10px;
			line-height: 15px;
			color: #000;
			a {
				text-decoration: underline;
				color: #000;
			}
		}
		p {
			font-size: 14px;
			line-height: 21px;
			color: #000;
		}
	}
	.appBox {
		background: #FFF;
		border-radius: 5px;
		position: relative;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		.appBoxHeader {
			height: 48px;
			border-bottom: 1px solid rgba(0,0,0,0.05);
			display: flex;
			align-items: center;
			padding: 0 86px 0 20px;
			position: relative;
			svg {
				margin: 0 26px 0 0;
			}
			h4 {
				color: #000;
				font-size: 14px;
				line-height: 21px;
				color: #101010;
			}
			.viewLink {
				font-size: 12px;
				line-height: 18px;
				color: #000;
				text-decoration: underline;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
			}
		}
		.appBoxContent {
			padding: 20px;
			.loader {
				position: absolute;
				left: 50%;
				top: 50%;
				margin: -15px 0 0 -15px;
			}
		}
	}
	.appContentRow {
		display: grid;
		grid-gap: 20px;
		row-gap: 20px;
		margin: 0 0 22px 0;
		&.appContentRow2 {
			grid-template-columns: 1fr 1fr;
		}
	}
	.pagination {
		text-align: center;
		padding: 24px 0;
		li {
			display: inline-block;
			margin: 0 4px;
			a {
				text-decoration: underline;
			}
			&.active {
				a {
					font-weight: bold;
					text-decoration: none;
				}
			}
		}
	}
}