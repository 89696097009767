.documentFolders {
	display: grid;
	column-gap: 20px;
	row-gap: 16px;
	grid-template-columns: 1fr 1fr 1fr;
	max-width: 658px;
	a {
		display: block;
		background: #FFF;
		border-radius: 5px;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		padding: 78px 32px 26px 32px;
		position: relative;
		text-align: center;
		svg {
			position: absolute;
			top: 32px;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
		}
		h4 {
			font-size: 12px;
			line-height: 18px;
			font-weight: bold;
			margin: 0 -20px 8px -20px;
			color: #000;
		}
		.documentFolderDescription {
			display: block;
			font-size: 10px;
			line-height: 15px;
			color: #000;
		}
		&:hover,
		&:active,
		&:focus {
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
		}
	}
}

.documentsTable {
	background: #FFF;
	border-radius: 5px;
	position: relative;
	::-webkit-scrollbar {
		width: 22px;
	}
	::-webkit-scrollbar-track {
		background: #FFF;
	}
	::-webkit-scrollbar-thumb {
		background: rgba(16, 16, 16, 0.2);
		border-radius: 20px;
		border: 8px solid #FFF;
		cursor: pointer;
	}
	.loader {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -15px 0 0 -15px;
	}
	.noDocumentsFound {
		text-align: center;
		padding: 24px;
	}
	table {
		width: 100%;
		th {
			&.documentsTableCellTitle { width: 32%; }
			&.documentsTableCellDescription { width: 40%; }
			&.documentsTableCellDateAdded { width: 18%; text-align: center; }
			&.documentsTableCellDownload { width: 10%; }
		}
		thead {
			height: 48px;
			display: table;
			width: 100%;
			table-layout: fixed;
			border-bottom: 1px solid rgba(16, 16, 16, 0.1);
			padding: 0 22px 0 0;
			th {
				padding: 15px 0 15px 31px;
				font-size: 12px;
				line-height: 18px;
				color: $primary-color;
				font-weight: bold;
			}
		}
		tbody {
			overflow-y: scroll;
			width: 100%;
			display: block;
			// max-height: 300px;
			padding: 0;
			height: calc(100vh - 325px);
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				td {
					padding: 31px 0 31px 31px;
					font-size: 10px;
					vertical-align: middle;
					line-height: 15px;
					color: $primary-color;
					border-bottom: 1px solid rgba(16, 16, 16, 0.05);
					&.documentsTableCellTitle {
						font-weight: bold;
						font-size: 12px;
						line-height: 18px;
						width: 32%;
					}
					&.documentsTableCellDescription { width: 40%; }
					&.documentsTableCellDateAdded { text-align: center; width: 18%; }
					&.documentsTableCellDownload { border-right: 1px solid rgba(16, 16, 16, 0.1); padding-right: 31px; width: 10%; }
				}
			}
		}
	}
}