.appLoginWrapper {
	text-align: center;
	padding: 41px 0 0 0;
	.container {
		max-width: 453px;
		margin: 0 auto;
		text-align: left;
	}
	h1 {
		font-size: 26px;
		line-height: 39px;
		text-align: center;
		font-weight: bold;
		color: #000;
		margin: 0 0 54px 0;
	}
	.appLoginBox {
		overflow: hidden;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
		background: #FFF;
		border-radius: 5px 5px 0 0;
		.appLoginBoxHeader {
			background: $primary-color;
			color: #FFF;
			font-size: 14px;
			line-height: 21px;
			font-weight: bold;
			text-align: center;
			padding: 16px 0 11px 0;
		}
		.appLoginBoxContent {
			position: relative;
			padding: 69px 154px 61px 154px;
			.authenticatorQrCodeWrapper {
				text-align: center;
				p {
					font-size: 12px;
					line-height: 18px;
				}
			}
			.appLoginBoxForm {
				.formGroupOtp {
					text-align: center;
					margin: 0 -32px 14px -32px;
					.formLabel {
						text-align: center;
						font-size: 12px;
						line-height: 18px;
						margin: 0 0 10px 0;
					}
					input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
						display: inline-block;
						margin: 0 3px;
						width: 25px;
						text-align: center;
						padding-left: 0px;
						padding-right: 0px;
					}
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					input[type=number] {
						-moz-appearance: textfield;
					}
				}
				.formSubmit {
					margin: 0 0 10px 0;
				}
				p {
					font-size: 8px;
					color: #000;
					text-align: center;
					line-height: 12px;
					a {
						text-decoration: underline;
						color: #000;
					}
				}
			}
			&.appLoginBoxContentHasQrCode {
				padding-left: 54px;
				padding-right: 54px;
				.form-submit {
					padding-left: 100px;
					padding-right: 100px;
				}
			}
			#failedLogin {
				position: absolute;
				top: 20px;
				left: 50%;
				transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				background: rgba(255, 60, 95, 0.2);
				padding: 5px 8px 4px 31px;
				border-radius: 2px;
				font-size: 8px;
				line-height: 12px;
				display: block;
				width: 246px;
				.failedLoginIcon {
					display: block;
					position: absolute;
					top: 4px;
					left: 10px;
					border-radius: 100%;
					border: 1px solid #101010;
					line-height: 11px;
					text-align: center;
					width: 13px;
				}
			}
			&.appLoginBoxContentSuccess {
				padding: 231px 64px 50px 64px;
			}
			.appLoginRedirectingMessage {
				text-align: center;
				font-size: 12px;
				line-height: 18px;
				display: block;
			}
		}
	}
}