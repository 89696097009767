.appSidebarCompanyName {
	border-bottom: 1px solid rgba(16, 16, 16, 0.5);
	padding: 20px 50px;
	font-size: 14px;
	line-height: 33px;
	min-height: 33px;
	color: #000;
	font-weight: 700;
	.companyLogo {
		border: 1px solid #000;
		border-radius: 100%;
		width: 33px;
		height: 33px;
		overflow: hidden;
		margin: 0 11px 0 0;
		display: inline-block;
		vertical-align: middle;
		img {
			max-width: 31px;
			max-height: 31px;
			height: auto;
		}
	}
}
.appSidebarNav {
	padding: 25px 0 15px 0;
	border-bottom: 1px solid rgba(16, 16, 16, 0.5);
	.appSidebarNavItem {
		margin: 0 0 10px 0;
		display: block;
		padding: 8px 50px 8px 96px;
		line-height: 21px;
		font-size: 14px;
		position: relative;
		color: #101010;
		svg {
			position: absolute;
			left: 50px;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
		}
		&:hover,
		&:active,
		&:focus {
			background: rgba(43, 59, 80, 0.15);
		}
	}
	&.appSidebarNav-current-dashboard {
		.appSidebarNavItemDashboard {
			background: rgba(43, 59, 80, 0.15);
			background: rgba(255, 232, 206, 0.5);
			&:after {
				display: block;
				width: 6px;
				height: 100%;
				border-radius: 0 4px 4px 0;
				background: $primary-color;
				background: #FFE8CE;
				content: '';
				position: absolute;
				left: 100%;
				top: 0px;
			}
		}
	}
	&.appSidebarNav-current-jobs {
		.appSidebarNavItemJobs {
			background: rgba(43, 59, 80, 0.15);
			background: rgba(255, 232, 206, 0.5);
			&:after {
				display: block;
				width: 6px;
				height: 100%;
				border-radius: 0 4px 4px 0;
				background: $primary-color;
				background: #FFE8CE;
				content: '';
				position: absolute;
				left: 100%;
				top: 0px;
			}
		}
	}
	&.appSidebarNav-current-documents {
		.appSidebarNavItemDocuments {
			background: rgba(43, 59, 80, 0.15);
			background: rgba(255, 232, 206, 0.5);
			&:after {
				display: block;
				width: 6px;
				height: 100%;
				border-radius: 0 4px 4px 0;
				background: $primary-color;
				background: #FFE8CE;
				content: '';
				position: absolute;
				left: 100%;
				top: 0px;
			}
		}
	}
	&.appSidebarNav-current-apps {
		.appSidebarNavItemApps {
			background: rgba(43, 59, 80, 0.15);
			background: rgba(255, 232, 206, 0.5);
			&:after {
				display: block;
				width: 6px;
				height: 100%;
				border-radius: 0 4px 4px 0;
				background: $primary-color;
				background: #FFE8CE;
				content: '';
				position: absolute;
				left: 100%;
				top: 0px;
			}
		}
	}
}
.appSidebarAdditionalNav {
	padding: 19px 0 30px 0;
	.appSidebarAdditionalNavItem {
		display: block;
		margin: 0px;
		padding: 11px 50px 11px 96px;
		line-height: 20px;
		font-size: 12px;
		position: relative;
		color: #000;
		text-decoration: underline;
		span {
			position: absolute;
			left: 50px;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			font-size: 20px;
		}
		img {
			position: absolute;
			right: 38px;
		}
	}
	&.appSidebarAdditionalNav-current-referrals {
		.appSidebarAdditionalNavItemReferrals {
			background: rgba(43, 59, 80, 0.15);
			background: rgba(255, 232, 206, 0.5);
			&:after {
				display: block;
				width: 6px;
				height: 100%;
				border-radius: 0 4px 4px 0;
				background: $primary-color;
				background: #FFE8CE;
				content: '';
				position: absolute;
				left: 100%;
				top: 0px;
			}
		}
	}
}