.appHeader {
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
	height: 60px;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 11;
	.container {
		padding: 0 50px;
		text-align: left;
	}
	.logoImage {
		float: left;
		margin: 19px 0 0 0;
	}
	.appHeaderNav {
		float: right;
		li {
			display: inline-block;
			position: relative;
			a {
				line-height: 60px;
				font-size: 14px;
				color: #101010;
				display: inline-block;
			}
			&.appHeaderNavLinkHasDropdown {
				> a {
					background: url('data:image/svg+xml,<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_d_452_629)"><path d="M10.8294 7.76866C10.433 8.35712 9.56697 8.35712 9.1706 7.76867L4.9877 1.55866C4.54029 0.894435 5.01624 0 5.81709 0H14.1829C14.9838 0 15.4597 0.894433 15.0123 1.55866L10.8294 7.76866Z" fill="%23101010"/></g><defs><filter id="filter0_d_452_629" x="0.81543" y="0" width="18.3691" height="16.21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_452_629"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_452_629" result="shape"/></filter></defs></svg>') no-repeat right 25px;
					padding: 0 27px 0 0;
				}
			}
			ul {
				visibility: hidden;
				opacity: 0;
				transition: $transition;
				position: absolute;
				top: 60px;
				right: 0px;
				background: #FFF;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
				z-index: 10;
				width: 160px;
				padding: 10px 0;
				li {
					display: block;
					a {
						display: block;
						background: none;
						font-size: 14px;
						line-height: 21px;
						padding: 10px 20px;
					}
				}
			}
			&:hover,
			&:active,
			&:focus {
				ul {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}