.appOnboardingWrapper {
	text-align: center;
	.onboardingHeader {
		padding: 41px 0 50px 0;
		h1 {
			font-size: 32px;
			line-height: 48px;
			color: #000;
			font-weight: bold;
			margin: 0 auto;
			max-width: 554px;
		}
		p {
			padding: 21px 0 0 0;
			font-size: 14px;
			line-height: 21px;
			margin: 0 auto;
			max-width: 474px;
		}
	}
	.onboardingFinalStep {
		text-align: right;
		padding: 43px 50px 0 0;
	}
	.onboardingAddTeamMembers {
		padding: 60px 0 100px 0;
		position: relative;
		margin: 0 auto;
		max-width: 842px;
		text-align: left;
		display: flex;
		header {
			width: 226px;
			flex-shrink: 0;
			flex-grow: 0;
			.onboardingAddTeamMembersCompanyName {
				position: relative;
				border-bottom: 1px solid rgba(43, 59, 80, 0.15);
				padding: 7px 17px 15px 62px;
				font-size: 14px;
				line-height: 21px;
				color: #000;
				font-weight: 700;
				.companyLogo {
					border: 1px solid #000;
					border-radius: 100%;
					width: 33px;
					height: 33px;
					margin: 0 11px 0 0;
					overflow: hidden;
					display: block;
					position: absolute;
					top: 0px;
					left: 17px;
					img {
						max-width: 31px;
						max-height: 31px;
						height: auto;
					}
				}
			}
			p {
				padding: 34px 27px 15px 20px;
				font-size: 12px;
				line-height: 18px;
			}
		}
		.onboardingAddTeamMembersTable {
			border-left: 1px solid rgba(43, 59, 80, 0.15);
			table {
				table-layout: fixed;
				width: 614px;
				thead {
					tr {
						th {
							border-right: 1px solid rgba(43, 59, 80, 0.15);
							padding: 7px 17px 15px 17px;
							font-size: 14px;
							line-height: 21px;
							color: #000;
							font-weight: 700;
							&.cellEmail { width: 40%; }
							&.cellMobile { width: 22%; }
						}
					}
				}
				tbody {
					tr {
						position: relative;
						td {
							border-right: 1px solid rgba(43, 59, 80, 0.15);
							border-top: 1px solid rgba(43, 59, 80, 0.15);
							padding: 11px 17px 11px 17px;
							font-size: 12px;
							line-height: 18px;
							color: #000;
							vertical-align: middle;
							font-weight: normal;
							height: 70px;
							text-overflow: ellipsis;
							overflow: hidden;
							&.hasInput {
								padding: 0px;
								input {
									width: 100%;
									display: block;
									-webkit-appearance: none;
									-moz-appearance: none;
									appearance: none;
									font-family: $text-font;
									color: $text-color;
									height: 70px;
									border: none;
									background: transparent;
									padding: 11px 17px 11px 17px;
									font-size: 12px;
									line-height: 18px;
									color: #000;
									&:focus {
										background: #FFF;
									}
								}
							}
							a {
								text-decoration: underline;
								color: #000;
								&.addNewTeamMemberLink {
									color: rgba(16, 16, 16, 0.5);
									&:hover,
									&:active,
									&:focus {
										color: rgba(16, 16, 16, 1);
									}
								}
							}
							.removeNewTeamMemberLink {
								position: absolute;
								top: 0px;
								right: -30px;
								padding: 0px;
								font-weight: normal;
								width: 30px;
								height: 72px;
								background: rgba(0,0,0,0.05);
								color: #000;
								border-radius: 0 4px 4px 0;
								&:hover,
								&:active,
								&:focus {
									background: rgba(0,0,0,0.1);
								}
							}
						}
					}
				}
			}
		}
	}
	.onboardingUpload {
		position: relative;
		.onboardingUploader {
			.onboardingUploaderBox {
				background: #FFF;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
				border-radius: 10px;
				height: 380px;
				width: 433px;
				max-width: 100%;
				margin: 0 auto 30px auto;
				position: relative;
				.onboardingUploaderContent {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					padding: 97px 38px 0 38px;
					.onboardingUploaderContentIcon {
						display: inline-block;
						width: 42px;
						line-height: 42px;
						background: #FFA59B;
						border-radius: 100%;
						color: #000;
						font-size: 30px;
						text-align: center;
						margin: 0 auto 40px auto;
					}
					h5 {
						font-size: 20px;
						line-height: 30px;
						color: #000;
						font-weight: bold;
						margin: 0 0 14px 0;
					}
					p {
						font-size: 16px;
						line-height: 24px;
					}

				}
				input[type="file"] {
					appearance: none;
					-webkit-appearance: none;
					-moz-appearance: none;
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background: #FFF;
					opacity: 0;
					z-index: 1;
					cursor: pointer;
				}
				.fileSelected {
					position: absolute;
					bottom: 0px;
					padding: 32px 94px 32px 94px;
					left: 0px;
					width: 100%;
					text-align: left;
					font-size: 14px;
					line-height: 21px;
					color: rgba(16, 16, 16, 0.5);
					z-index: 2;
					transition: $transition;
					a {
						float: right;
						border-radius: 4px;
						padding: 10px 16px 6px 16px;
						margin: -10px -16px 0 0;
						&:hover,
						&:active,
						&:focus {
							background: rgba(0,0,0,0.1);
						}
					}
					&:hover,
					&:active,
					&:focus {
						color: #101010;
					}
				}
				&:hover,
				&:active,
				&:focus {
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
				}
			}
			.onboardingUploadDescriptionBox {
				background: #FFF;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
				border-radius: 10px;
				height: 380px;
				width: 433px;
				max-width: 100%;
				margin: 0 auto 30px auto;
				position: relative;
				padding: 30px 30px 30px 30px;
				.onboardingLoader {
					height: 41px;
					width: 41px;
					display: block;
					position: relative;
					margin: 0 auto 11px auto;
					border-radius: 50%;
					background: #FFA59B;
					.onboardingLoaderHalf {
						background: #FFF;
						height: 41px;
						width: 20.5px;
						display: block;
						position: absolute;
						top: 0px;
						left: 0px;
					}
					.onboardingLoaderThreeQuarter {
						background: #FFF;
						height: 41px;
						width: 20.5px;
						display: block;
						position: absolute;
						top: -20.5px;
						left: 0px;
					}
				}
				h5 {
					font-size: 20px;
					line-height: 30px;
					font-weight: bold;
					margin: 0 0 40px 0;
				}
			}
			.onboardingUploadCategoryBox {
				background: #FFF;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
				border-radius: 10px;
				min-height: 380px;
				width: 433px;
				max-width: 100%;
				margin: 0 auto 30px auto;
				position: relative;
				padding: 30px 30px 30px 30px;
				.onboardingLoader {
					background: #FFA59B;
					height: 41px;
					width: 41px;
					display: block;
					margin: 0 auto 11px auto;
					border-radius: 100%;
				}
				.formCheckboxes {
					padding: 16px 44px 0 16px;
				}
				h5 {
					font-size: 20px;
					line-height: 30px;
					font-weight: bold;
					margin: 0 0 40px 0;
				}
			}
		}
	}
	.onboardingUploadUploading {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.95);
		text-align: center;
		padding: 148px 0 0 0;
		font-size: 16px;
		line-height: 21px;
		z-index: 100;
		.loader {
			margin: 0 auto 20px auto;
		}
	}
	textarea {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		height: 195px;
		background: rgba(242, 242, 242, 0.5);
		font-size: 14px;
		line-height: 21px;
		font-family: $text-font;
		padding: 14px 16px;
		border-radius: 5px;
		border: none;
		width: 100%;
		resize: none;
	}
	.onboardingUploadHasUploaded {
		width: 793px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.onboardingUpload {
			width: 340px;
			flex-grow: 0;
			flex-shrink: 0;
			.onboardingUploader {
				.onboardingUploaderBox {
					.fileSelected {
						padding: 32px 48px 32px 48px;
					}
				}
				.onboardingUploadCategoryBox {
					.formCheckboxes {
						padding: 16px 16px 0 16px;
					}
				}
			}
		}
		.onboardingUploaded {
			width: 360px;
			text-align: left;
			h4 {
				float: left;
				font-size: 20px;
				line-height: 30px;
				border-bottom: 1px solid #000;
				padding: 0 0 4px 0;
				margin: 0 0 25px 0;
			}
			.onboardingUploadedFile {
				clear: both;
				margin: 0 0 16px 0;
				padding: 0;
				left: 0px;
				width: 100%;
				text-align: left;
				font-size: 14px;
				line-height: 21px;
				color: rgba(16, 16, 16, 0.5);
				z-index: 2;
				a {
					float: right;
					border-radius: 4px;
					padding: 10px 16px 6px 16px;
					margin: -10px -16px 0 0;
					&:hover,
					&:active,
					&:focus {
						background: rgba(0,0,0,0.1);
					}
				}
			}
		}
	}
	.onboardingQuestionnaireForm {
		.onboardingQuestionnaireBox {
			background: #FFF;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
			border-radius: 10px;
			min-height: 380px;
			width: 433px;
			max-width: 100%;
			margin: 0 auto 30px auto;
			position: relative;
			padding: 30px 40px 30px 40px;
			text-align: left;
			h4 {
				font-size: 20px;
				line-height: 30px;
				font-weight: bold;
				margin: 0 0 21px 0;
			}
			h5 {
				padding: 4px 0 0 0;
				font-size: 12px;
				line-height: 18px;
				font-weight: bold;
				margin: 0 0 8px 0;
			}
			.formCheckboxes {
				.formCheckbox {
					margin: 0 0 11px 0;
				}
			}
			&.onboardingQuestionnaireBoxTwelveMonthExpectation {
				text-align: center;
				width: 700px;
				padding: 30px 112px 26px 112px;
			}
			.onboardingQuote {
				text-align: center;
				img {
					margin: 0 auto 15px auto;
					height: 41px;
				}
				p {
					font-size: 20px;
					line-height: 30px;
					color: #FFA59B;
					margin: 0 0 30px 0;
					font-weight: bold; 
				}
			}
			textarea {
				height: 134px;
			}
		}
	}
	.formSubmit {
		.button {
			width: 143px;
			margin: 0 0 10px 0;
		}
	}
}
.skipLink {
	clear: both;
	margin: 10px 0 0 0;
	text-decoration: underline;
	color: rgba(16, 16, 16, 0.5);
}